export const BG_PRIMARY = '#E7E9E8';
export const BG_SECONDARY = '#BCB0B2';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#86550a';
export const BG_SECONDARY_TRANSPARENT = '';
export const BG_ALTERNATIVE = '#8E8F91';
export const TEXT_PRIMARY_LIGHT = '#FFF';
export const TEXT_PRIMARY = '#352023';
export const TEXT_PRIMARY_DARK = BG_SECONDARY_DARK;
export const TEXT_SECONDARY = '#FFF';
export const BTN_NAVIGATION_COLOR = '#444445';

export const DEFAULT_BUTTON = '#8E8F91';

export const HEADING_STYLES = {
  color: '#352023',
  fontFamily: 'heading',
  fontSize: '26px',
  letterSpacing: '3px',
  textAlign: 'center',
  textTransform: 'uppercase',
  //paddingLeft: '28px',
};

export const LINK_STYLES = {
  color: '#CFB4B4',
  textDecoration: 'underline',
  fontWeight: 'bold',
  fontStyle: 'italic',
};

export const DEFAULT_BUTTON_PROPS = {};
export const BUTTON_PROPS = {};